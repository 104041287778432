<template>
    <v-container>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <b-alert
                            :show="segundosMensajeActualizacion"
                            dismissible
                            :variant="mensaje.variant"
                            @dismissed="segundosMensajeActualizacion = 0"
                            @dismiss-count-down="
                                actualizarSegundosMensajeActualizacion
                            "
                        >
                            {{ mensaje.texto }}
                        </b-alert>
                        <div class="d-flex">
                            <div class="card-title col-auto me-auto">
                                App Flex Energeticas
                            </div>
                            <button
                                v-if="rolModuloPermiso.escritura"
                                class="btn btn-primary col-auto"
                                @click="abrirModalNuevo"
                            >
                            <i class="mdi mdi-plus me-1"></i>
                                Nuevo
                            </button>
                        </div>
                        <div class="d-flex">
                            
                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div
                                    id="tickets-table_length"
                                    class="dataTables_length"
                                >
                                    <label
                                        class="d-inline-flex align-items-center"
                                    >
                                        Mostrar&nbsp;
                                        <b-form-select
                                            class="form-select form-select-sm"
                                            v-model="perPage"
                                            size="sm"
                                            :options="pageOptions"
                                        ></b-form-select>
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                    id="tickets-table_filter"
                                    class="dataTables_filter text-md-end"
                                >
                                    <label
                                        class="d-inline-flex align-items-center"
                                    >
                                        Buscar:
                                        <b-form-input
                                            v-model="filter"
                                            type="search"
                                            placeholder="Buscar..."
                                            class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                class="datatables tabla-personalizada"
                                :items="appFlexEnergeticas"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                select-mode="single"
                                selectable
                                @filtered="onFiltered"
                                :busy="appFlexEnergeticasCargando"
                            >   
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    </div>
                                </template>
                                <template #cell(accion)="data" v-if="rolModuloPermiso.escritura">
                                    <i
                                        class="bx bx-edit h3 text-primary pointer me-2"
                                        @click="abrirModalEditar(data.item)"
                                    ></i>
                                    <i
                                        class="bx bx-trash h3 text-primary pointer"
                                        @click="
                                            eliminarAppFlexEnergeticaLocal(data.item)
                                        "
                                    ></i>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div
                                    class="dataTables_paginate paging_simple_numbers float-end"
                                >
                                    <ul
                                        class="pagination pagination-rounded mb-0"
                                    >
                                        <!-- pagination -->
                                        <b-pagination
                                            v-model="currentPage"
                                            :total-rows="totalRows"
                                            :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal
            v-model="mostrarModal"
            title="Agregar App Flex Energetica"
            hide-footer
            size="md"
        >   
            
            <loading-flux :value="loading"></loading-flux>
        
            <form @submit.prevent="submit">
                <div class="row">
                    <div class="col-12">
                        <div class="mb-3">
                            <label for="nombreAppFlexEnergetica">Nombre App Flex Energetica</label>
                            <input
                                id="nombreAppFlexEnergetica"
                                v-model="$v.form.nombreAppFlexEnergetica.$model"
                                type="text"
                                class="form-control"
                                placeholder="Nombre App Flex Energetica"
                                :class="{
                                    'is-invalid':
                                        submitted && $v.form.nombreAppFlexEnergetica.$error,
                                }"
                            />
                            <div
                                v-if="submitted && !$v.form.nombreAppFlexEnergetica.required"
                                class="invalid-feedback"
                            >
                                El campo es obligatorio.
                            </div>
                        </div>
                    </div>

                </div>
                <div class="text-end mt-3">
                    <b-button variant="light" @click="mostrarModal = false"
                        >Cerrar</b-button
                    >
                    <b-button v-if="rolModuloPermiso.escritura" type="submit" variant="success" class="ms-1"
                        >Guardar</b-button
                    >
                </div>
            </form>
        </b-modal>
    </v-container>
</template>
<script>
//import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { appFlexEnergeticaMethods, appFlexEnergeticaComputed } from "@/state/helpers";
import Swal from "sweetalert2";
import {authMethods,authUsuarioComputed} from "@/state/helpers";
import {permiso} from "@/helpers/authservice/obtener-permisos";
import moment from "moment";
moment.locale("es");
export default {
    mixins: [validationMixin],
    page: {
        title: "App Flex Energeticas",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        //Layout,
        PageHeader
    },

    data() {
        return {
            loading:false,
            mensaje: { variant: "", texto: "" },
            segundos: 5,
            segundosMensajeActualizacion: 0,
            proyectos: [],
            title: "App Flex Energeticas",
            items: [
                {
                    text: "Mantenedores",
                },
                {
                    text: "Proyectos",
                },
                {
                    text: "App Flex Energeticas",
                    active: true,
                }
            ],
            totalRows: 1,
            todoTotalRows: 1,
            currentPage: 1,
            todocurrentPage: 1,
            perPage: 10,
            todoperPage: 10,
            pageOptions: [10, 25, 50, 100],
            todoOptions: [10, 50, 100, 150, 200],
            filter: null,
            todoFilter: null,
            filterOn: [],
            todofilterOn: [],
            sortBy: "Proyecto",
            sortDesc: false,
            fields: [
                {
                    key: "Accion",
                    sortable: false,
                },
                {
                    key: "app_flex_energetica_id",
                    label: "App Flex Energetica Id",
                    sortable: true,
                },
                {
                    key: "nombre_app_flex_energetica",
                    sortable: true,
                },
                
            ],
            appFlexEnergeticaSeleccionado: {},
            esEditar: false,
            submitted: false,
            mostrarModal: false,
            appFlexEnergeticas: [],
            appFlexEnergeticasCargando: false,
            form: {
                nombreAppFlexEnergetica: null,

            },
            rolModuloPermiso:{}
        };
    },
    validations: {
        form: {
            nombreAppFlexEnergetica: { required },
        },
    },
    mounted() {

        this.obtenerAppFlexEnergeticasLocal();
        let permisos = permiso.obtenerPermisos(this.user,this.$router.currentRoute.name)
        this.rolModuloPermiso = permisos
    },
    methods: {
        ...appFlexEnergeticaMethods,
        actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
            this.segundosMensajeActualizacion = segundosMensajeActualizacion;
        },
        abrirModalNuevo() {
            this.resetForm();
            this.esEditar = false;
            this.submitted = false;
            this.mostrarModal = true;
        },
        abrirModalEditar(datos) {
            this.appFlexEnergeticaSeleccionado = datos;
            this.resetForm();

            this.$v.form.nombreAppFlexEnergetica.$model = datos.nombre_app_flex_energetica;

            this.esEditar = true;
            this.submitted = false;
            this.mostrarModal = true;
        },

        obtenerAppFlexEnergeticasLocal() {
            this.appFlexEnergeticasCargando = true;
            this.obtenerAppFlexEnergeticas()
                .then((res) => {
                    this.appFlexEnergeticas = res.body;
                    this.totalRows = this.appFlexEnergeticas.length
                    this.appFlexEnergeticasCargando = false;
                })
                .catch((error) => {
                    this.appFlexEnergeticasCargando = false;
                });
        },

        submit(e) {
            this.loading = true
            this.submitted = true;
            this.$v.form.$touch();

            if (this.$v.form.$invalid) {
                this.loading = false
                return;
            }

            let app_flex_energetica_id = "";
            if (this.esEditar) {
                app_flex_energetica_id = this.appFlexEnergeticaSeleccionado.app_flex_energetica_id;
            }

            const nombre_app_flex_energetica = this.$v.form.nombreAppFlexEnergetica.$model;

            let nuevoAppFlexEnergetica = {app_flex_energetica_id,
                    nombre_app_flex_energetica}
            
            if (this.esEditar) {
                this.actualizarAppFlexEnergetica(nuevoAppFlexEnergetica)
                    .then((res) => {
                        if (res.status == 202) {
                            this.mensaje.variant = "danger";
                            this.mensaje.texto =
                                "Ha ocurrido un error, intente nuevamente";
                        }

                        if (res.status == 204) {
                            this.mensaje.variant = "success";
                            this.mensaje.texto =
                                "App Flex Energetica actualizado correctamente!!!";
                            this.mostrarModal = false;
                        }

                        this.segundosMensajeActualizacion = this.segundos;
                        this.obtenerAppFlexEnergeticasLocal();
                        this.loading = false
                    })
                    .catch((error) => {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";
                        this.mostrarModal = false;
                        this.segundosMensajeActualizacion = this.segundos;
                        this.loading = false
                    });
            } else {
                this.crearAppFlexEnergetica(nuevoAppFlexEnergetica)
                    .then((res) => {
                        if (res.status == 201) {
                            this.mensaje.variant = "success";
                            this.mensaje.texto =
                                "App Flex Energetica creado correctamente!!!";
                            this.mostrarModal = false;
                        }

                        if (res.status == 208) {
                            this.mensaje.variant = "danger";
                            this.mensaje.texto = "App Flex Energetica ya existe!!!";
                        }

                        this.segundosMensajeActualizacion = this.segundos;
                        this.obtenerAppFlexEnergeticasLocal();
                    })
                    .catch((error) => {
                        this.mensaje.variant = "danger";
                        this.mensaje.texto =
                            "Ha ocurrido un error, intente nuevamente";

                        this.segundosMensajeActualizacion = this.segundos;
                    });
            }

            this.submitted = false;
        },
        resetForm() {
            this.form = {
                nombreAppFlexEnergetica: null,
            };

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        eliminarAppFlexEnergeticaLocal(item) {
            const mensajeEliminar = Swal.mixin({
                customClass: {
                    title: "font-size-18",
                    confirmButton: "btn btn-danger ms-2",
                    cancelButton: "btn btn-gris-claro",
                },
                buttonsStyling: false,
            });
            mensajeEliminar
                .fire({
                    title: "Esta seguro que desea eliminar el registro?",
                    text: "",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Eliminar",
                    cancelButtonText: "Cancelar",
                    allowOutsideClick: false,
                    reverseButtons: true
                })
                .then((result) => {
                    if (result.value) {
                        this.eliminarAppFlexEnergetica(item.app_flex_energetica_id)
                            .then((res) => {
                                if (res.status == 202) {
                                    this.mensaje.variant = "danger";
                                    this.mensaje.texto =
                                        "Ha ocurrido un error, intente nuevamente";
                                }

                                if (res.status == 204) {
                                    this.mensaje.variant = "success";
                                    this.mensaje.texto =
                                        "App Flex Energetica eliminado correctamente!!!";
                                    this.mostrarModal = false;
                                }
                                this.segundosMensajeActualizacion = this.segundos;
                                this.obtenerAppFlexEnergeticasLocal();
                            })
                            .catch((error) => {
                                this.mensaje.variant = "danger";
                                this.mensaje.texto =
                                    "No se ha eliminado el registro, intente nuevamente";
                                this.mostrarModal = false;
                                this.segundosMensajeActualizacion = this.segundos;
                            });
                    }
                });
        },
        formatDate(value) {
            const duration = moment(value).format("DD/MM/yyyy");
            return `${duration}`;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        todoFiltered(filteredItems) {
            this.todoTotalRows = filteredItems.length;
            this.todocurrentPage = 1;
        },

        handleInput(value, data) {
            this.editableDataItems[data.index][data.field.key] = value;
        },
    },
    computed: {
        ...authUsuarioComputed,
        // ...appFlexEnergeticaComputed,
        rows() {
            return 1;
        },

        /**
         * Todo list of records
         */
        todoList() {
            return this.todos.length;
        },
    },
};
</script>

